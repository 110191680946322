'use client';

import { Configure, InstantSearch, useHits, useInfiniteHits, usePagination, useSortBy } from 'react-instantsearch';
import { CommonButton } from 'components/atoms/buttons/common-buttons/button';
import { SortingButton } from 'components/atoms/buttons/sorting-button';
import Pagination from 'components/atoms/pagination';
import { Typography } from 'components/atoms/typography';
import { janeSearchClient } from 'helpers/algolia/clients';
import { getJaneIndexName } from 'helpers/algolia/get-index-name';
import { cardListClickedTrack } from 'helpers/analytics/card-list-clicked-track';
import useProductReview from 'helpers/hooks/use-product-review';
import FilteredReviews from './filtered-reviews';
import MostRated from './most-rated';
import OverallRatings from './overall-ratings';
import SelectedPills from './selected-pills';
import {
  CustomerReviewWrapper,
  FlexClass,
  MostRatedItemsGrid,
  PaginationMarginDiv,
  ReviewsContainer,
  ShowMoreButton,
  ShowMoreContainer,
  ShowReviewsContainer,
  SortingSelectionContainer,
  TopHeadingStyles,
} from './styles';

type TProps = {
  tasticPlacementNumber?: number;
};
const CustomerReview = ({ tasticPlacementNumber }: TProps) => {
  const {
    fiterPills,
    selected,
    handleFilter,
    handleClearAll,
    totalPage,
    averageRating,
    rating,
    showAllReview,
    handleShowReviews,
    filters,
  } = useProductReview();

  const ReviewsHit = (props) => {
    const { hits } = useHits(props);
    return <FilteredReviews reviewData={hits} />;
  };

  const ShowMoreHit = (props: any) => {
    const { results } = useInfiniteHits(props);
    const { options, refine } = useSortBy({
      ...props,
      items: [
        { label: 'Most Recent', value: getJaneIndexName('product-reviews') },
        { label: 'Most Helpful', value: getJaneIndexName('product-reviews-by-upvote') },
        { label: 'Rating', value: getJaneIndexName('product-reviews-by-rating') },
      ],
    });

    if (!results) return null;

    const startIndex = results?.page * results.hitsPerPage;
    const endIndex =
      startIndex + results.hitsPerPage > results.nbHits ? results.nbHits : startIndex + results.hitsPerPage;
    const buttonText = `Show ${results?.nbHits || 0} reviews`;

    const handleSegmentEvent = () => {
      const cardListData = {
        card_list_name: 'Customer Product Reviews',
        card_list_title: 'Customer Review',
        card_list_type: '',
        card_list_facet_name: '',
        card_list_facet_value: '',
        card_list_url: '',
        link_name: buttonText,
        placement: tasticPlacementNumber || 0,
        link_type: 'hyperlink',
      };
      cardListClickedTrack(cardListData);
    };

    const onSelect = (e) => {
      const selectedItem = options?.find((item) => item?.label === e);
      selectedItem && refine(selectedItem?.value);
    };

    return (
      <ShowMoreContainer>
        <MostRatedItemsGrid>
          {Object.entries(fiterPills).map(
            ([name, item]) =>
              item &&
              item?.length > 0 && (
                <MostRated key={name} category={name} data={item} onPress={handleFilter} selected={selected} />
              ),
          )}
        </MostRatedItemsGrid>
        {!showAllReview && (
          <CommonButton
            button-type="secondary"
            color="green"
            className={ShowMoreButton}
            onClick={() => {
              handleShowReviews();
              handleSegmentEvent();
            }}
            data-testid="show-more-button"
          >
            {buttonText || ''}
          </CommonButton>
        )}
        {showAllReview && (
          <SortingSelectionContainer>
            <Typography className={'pb-6 !text-grey-400 xl:pb-0 xl:pb-0'} variant="body-small-bold">
              {`${startIndex + 1}-${endIndex} of ${results.nbHits} reviews`}
            </Typography>
            <SortingButton
              placement={tasticPlacementNumber}
              card_list_name="Customer Product Reviews"
              card_list_title="Customer Review"
              card_list_type="List"
              label={'Sort By'}
              onSelect={onSelect}
              options={options?.map((item) => item?.label)}
            />
          </SortingSelectionContainer>
        )}
      </ShowMoreContainer>
    );
  };

  const PaginationHit = (props: any) => {
    const { nbHits, refine, currentRefinement, isFirstPage } = usePagination(props);
    return (
      <PaginationMarginDiv>
        <Pagination
          count={nbHits}
          color="resin"
          variant="large"
          onPageChange={(x) => {
            if (currentRefinement < x.page) refine(currentRefinement + 1);
            else refine(currentRefinement - 1);
          }}
          itemsPerPage={5}
          slickCurrentIndex={isFirstPage ? 0 : undefined}
        />
      </PaginationMarginDiv>
    );
  };

  const RatingsHit = () => {
    return (
      <OverallRatings
        averageRating={averageRating || 0}
        selected={selected}
        onPress={handleFilter}
        totalReviews={totalPage}
        data={rating}
      />
    );
  };

  const CustomerReviewCard = () => {
    return (
      <CustomerReviewWrapper id="customerreview">
        <Typography variant="h3" className={TopHeadingStyles}>
          Customer Review
        </Typography>
        <FlexClass>
          <RatingsHit />
          <ReviewsContainer>
            <ShowMoreHit />
            {showAllReview && (
              <ShowReviewsContainer>
                <hr className="mt-10 block xl:hidden" />
                {selected?.length > 0 && (
                  <SelectedPills selectedItems={selected} handleItemClick={handleFilter} onClearAll={handleClearAll} />
                )}
                <ReviewsHit />
                <PaginationHit />
              </ShowReviewsContainer>
            )}
          </ReviewsContainer>
        </FlexClass>
      </CustomerReviewWrapper>
    );
  };
  return (
    <InstantSearch indexName={getJaneIndexName('product-reviews')} searchClient={janeSearchClient}>
      <Configure analytics={false} filters={filters} hitsPerPage={5} />
      <CustomerReviewCard />
    </InstantSearch>
  );
};

export default CustomerReview;
