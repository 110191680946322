'use client';

import { usePathname } from 'next/navigation';
import { WEIGHT_OBJECT } from 'helpers/constants/product';
import { capitalizeLabel, extractPrices, formatWeight, getDefaultProductImageUrl } from 'helpers/utils/product-details';
import { useAppSelector } from 'redux/hooks';
import { RootState } from 'redux/store';

type TProps = {
  pageFolder?: any;
};
const useProductDetails = ({ pageFolder }: TProps) => {
  const { variants, variants_details, seoMetaData, is_out_of_stock, cms } =
    (pageFolder &&
      pageFolder?.dataSourceConfigurations?.find((item) => item?.streamId === '__master').preloadedValue) ||
    {};
  const host = useAppSelector((state: RootState) => state?.hostReducer.host || '');
  const isOH = host?.includes('oh');
  const size =
    variants &&
    (Object.entries(variants) as [string, { amount?: any; product_id?: any }][])?.map(([name, data]) => {
      const variant = name !== 'null' ? name : 'each';
      return {
        label: data?.amount || capitalizeLabel(WEIGHT_OBJECT[variant] || variant),
        name: formatWeight(variant),
        value: Number(data?.product_id),
      };
    });
  const path = usePathname();
  const { product_id, code } = useAppSelector((state: RootState) => state?.product || null);
  const productData = variants_details[product_id || path?.split('/')[7]]
    ? { ...variants_details[product_id || path?.split('/')[7]] }
    : {};
  const defaultWeightSelect = code
    ? code
    : productData?.price_eighth_ounce
    ? 'eighth_ounce'
    : size?.find((item) => productData?.available_weights?.includes(item?.name?.replace('_', ' ')))?.name ||
      size?.[0]?.name;
  const images = productData?.image_urls?.map((item) => ({
    url: item,
    alt: productData?.name || '',
    title: productData?.name || '',
  }));
  const inventory_potencies =
    productData?.inventory_potencies?.find((item) => item?.price_id === (code || defaultWeightSelect)) ||
    productData?.inventory_potencies?.[0];
  const { thc_potency, tac_potency, price_id, cbd_potency } = inventory_potencies || {};
  const formatTags = (type: string) => {
    switch (type) {
      case 'vape':
      case 'flower':
      case 'pre-roll':
      case 'extract':
        return [
          capitalizeLabel(productData?.category),
          thc_potency || productData?.percent_thc ? `THC ${thc_potency || productData?.percent_thc}%` : null,
          cbd_potency || productData?.percent_cbd ? `CBD ${cbd_potency || productData?.percent_cbd}%` : null,
          tac_potency || productData?.percent_thca ? `THCa ${tac_potency || productData?.percent_thca}%` : null,
        ];
      case 'edible':
      case 'tincture':
      case 'topical':
        return [capitalizeLabel(productData?.category), productData?.dosage || null, productData?.amount || null];
      default:
        return [];
    }
  };
  const tags = formatTags(productData.kind);
  const { price, discounted_price, max_cart_quantity } = extractPrices(price_id, productData);
  const productDetailsData = {
    averageRating: (!isOH && productData?.aggregate_rating) || null,
    brand: productData?.brand,
    productType: (productData?.brand_subtype && capitalizeLabel(productData?.brand_subtype)) || '',
    productDescription: productData?.store_notes || productData?.description,
    strain: productData?.name,
    reviews: (!isOH && productData?.review_count) || null,
    productSizes: size || [],
    dosage: productData?.dosage,
    images:
      images?.length > 0
        ? images
        : [
            {
              url: getDefaultProductImageUrl(
                productData?.kind === 'flower' ? productData.kind : productData?.type,
                productData?.category,
                productData?.root_subtype,
              ),
            },
          ],
    tags,
    kind: productData.kind,
    maxCartLimit: max_cart_quantity || productData?.max_cart_quantity,
    price: discounted_price ? parseFloat(discounted_price) : parseFloat(price) || null,
    originalPrice:
      discounted_price && String(parseFloat(discounted_price)) !== String(parseFloat(price)) ? parseFloat(price) : null,
    isShowWeightSelector: productData?.available_weights?.length > 0 || false,
    defaultWeightSelect: String(defaultWeightSelect) || '',
    productId: productData.product_id,
    storeId: parseInt(path?.split('/')[4]),
    offerText: productData?.special_title || null,
    outOfStock: is_out_of_stock,
  };
  return {
    productDetailsData,
    productId: product_id,
    reviewCount: !isOH && productData?.review_count,
    seoMetaData: {
      seoTitle: seoMetaData?.name || '',
      ogImage: {
        url: seoMetaData?.images?.[0] || '',
      },
      ogType: seoMetaData?.['@type'],
      ogSite: 'risecannabis.com',
      canonicalUrl: window?.location?.href,
      productSeoMetaData: seoMetaData,
      ...seoMetaData,
    },
    cms,
    labResults: productData?.lab_results?.find((result) => result?.price_id === defaultWeightSelect) || {},
  };
};

export default useProductDetails;
