import { Progress } from '@material-tailwind/react/components/Progress';
import { Typography } from 'components/atoms/typography';
import { ProgressBarWrapper } from './styles';

interface IProgressBarProps {
  ratingCount: number;
  totalReviews: number;
}

export const ProgressLabelOutside = ({ ratingCount, totalReviews }: IProgressBarProps) => {
  const percentage = Math.floor((ratingCount / totalReviews) * 100);
  return (
    <ProgressBarWrapper>
      <div className="flex w-[116px] items-start lg:w-[209px]  2xl:w-[216px]">
        <Progress value={Number(percentage)} size="sm" className="[&_div]:bg-resin" data-testid="progress-bar" />
      </div>
      <Typography variant="body-large" className="w-[40px] text-right">
        {percentage}%
      </Typography>
    </ProgressBarWrapper>
  );
};
