'use client';

import { FunctionComponent } from 'react';
import CustomerReview from 'components/organisms/customer-review';
import useProductDetails from 'helpers/hooks/use-product-details';

const CustomerReviewTastics: FunctionComponent = ({ pageFolder, data }: any) => {
  const { reviewCount } = useProductDetails({ pageFolder });
  return reviewCount ? <CustomerReview tasticPlacementNumber={data?.tasticPlacementNumber} /> : null;
};

export default CustomerReviewTastics;
