import { useCallback } from 'react';
import { FilterPillButton } from 'components/atoms/buttons/filter-pill';
import { Typography } from 'components/atoms/typography';
import FilterCarousel from './filter-carousel';
import { FilterPillButtonWrapper, MostRatedContainerTitle } from './styles';

interface MostRatedProps {
  category: string;
  onPress: (item: { label: string; icon: JSX.Element; count?: number; value: string; key: string }) => void;
  selected: Array<{ label: string; count?: number; value: string; key: string }>;
  data: Array<{ label: string; count?: number; icon: JSX.Element; value: string }>;
}

const MostRated = ({ category, onPress, selected, data }: MostRatedProps) => {
  const handleOnPress = useCallback(async ({ value, icon, label }) => {
    await onPress({ value, icon, label, key: category });
  }, []);

  return (
    <div data-testid="most-rated-component" className="flex flex-col gap-5">
      <Typography variant="h5" className={` ${MostRatedContainerTitle}`}>
        {`Top ${category?.toUpperCase()} Mentioned`}
      </Typography>
      <div data-testid={`most-rated-${category}-component`}>
        <FilterPillButtonWrapper>
          {data &&
            data?.map(({ count, value, icon, label }, index) => (
              <FilterPillButton
                label={`${label} (${count})`}
                icon={icon}
                defaultSelected={selected?.some((item) => item?.label === label)}
                onClick={() => handleOnPress({ value, icon, label })}
                key={`filter-pill-${index}`}
                dataTestTd={`filter-pill-${index}`}
              />
            ))}
        </FilterPillButtonWrapper>
        <FilterCarousel selected={selected} handleOnPress={handleOnPress} data={data} />
      </div>
    </div>
  );
};

export default MostRated;
