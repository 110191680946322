'use client';

import React, { useEffect, useState } from 'react';
import { Tooltip } from '@material-tailwind/react';
import { IconButton } from 'components/atoms/buttons/icon-button/icon-button';
import { icons } from 'helpers/constants/review-tags';
import useMediaQuery from 'helpers/hooks/useMediaQuery';
import { tablet } from 'helpers/utils/screensizes';
import { IconnButtonStyles } from './styles';

type TProps = {
  item: string;
};
const ReviewIcon = ({ item }: TProps) => {
  const [isTablet] = useMediaQuery(tablet);
  const [open, setOpen] = useState(false);
  useEffect(() => {
    if (open) {
      setTimeout(() => setOpen(false), 2000);
    }
  }, [open]);
  return (
    <Tooltip content={item} open={open} className="bg-gray-600" placement="bottom">
      <div
        onClick={() => (!isTablet ? setOpen(!open) : null)}
        onMouseEnter={() => (isTablet ? setOpen(true) : null)}
        onMouseLeave={() => setOpen(false)}
        aria-hidden="true"
      >
        <IconButton button-type="secondary" className={IconnButtonStyles} color="green" title={item}>
          {icons[item]}
        </IconButton>
      </div>
    </Tooltip>
  );
};

export default ReviewIcon;
