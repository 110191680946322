'use client';

import { useRef, useState } from 'react';
import Slider from 'react-slick';
import { FilterPillButton } from 'components/atoms/buttons/filter-pill';
import { NextIcon, PrevIcon } from 'components/atoms/pagination/style';
import 'slick-carousel/slick/slick-theme.css';
import 'slick-carousel/slick/slick.css';
import {
  FilterCarouselContainer,
  NextIconContainer,
  NextIconGredient,
  NextIconWrapper,
  PreviousIconContainer,
  PreviousIconGredient,
  PreviousIconWrapper,
} from './styles';
type TProps = {
  data: any;
  handleOnPress: ({ value, icon, label }: { value: number; icon: JSX.Element; label: string }) => void;
  selected: any;
};
const FilterCarousel = ({ data, handleOnPress, selected }: TProps) => {
  const [currentIndex, setCurrentIndex] = useState(0);
  const isPreviousButtonHidden = currentIndex === 0;
  const isNextButtonHidden = currentIndex === data?.length - 1;
  const slickRef = useRef(null);
  const handlePrevious = () => {
    //@ts-ignore
    slickRef.current.slickPrev();
  };

  const handleNext = () => {
    //@ts-ignore
    slickRef.current.slickNext();
  };

  const settings = {
    lazyLoading: 'ondemand',
    dots: false,
    infinite: false,
    slidesToShow: 1,
    slidesToScroll: 1,
    arrows: false,
    speed: 100,
    variableWidth: true,
  };
  return (
    <FilterCarouselContainer data-testid="filter-carousel">
      {!isPreviousButtonHidden && (
        <PreviousIconContainer>
          <PreviousIconWrapper>
            <PrevIcon
              $color={'leaf'}
              onClick={handlePrevious}
              className={` ${'mr-2 h-6 w-6'} `}
              data-testid="previous-button"
            />
          </PreviousIconWrapper>
          <PreviousIconGredient />
        </PreviousIconContainer>
      )}
      {!isNextButtonHidden && (
        <NextIconContainer>
          <NextIconGredient />
          <NextIconWrapper>
            <NextIcon
              $color={'leaf'}
              onClick={handleNext}
              className={` ${'mr-2 h-6 w-6'} `}
              data-testid="next-button"
            />
          </NextIconWrapper>
        </NextIconContainer>
      )}
      <div className="w-full">
        <Slider
          ref={slickRef}
          {...settings}
          afterChange={(currentSlide) => {
            setCurrentIndex(currentSlide);
          }}
        >
          {data &&
            data?.map(({ count, value, icon, label }, index) => (
              <FilterPillButton
                label={`${label} (${count})`}
                icon={icon}
                defaultSelected={selected?.some((item) => item?.label === label)}
                onClick={() => handleOnPress({ value, icon, label })}
                key={index}
                dataTestTd={`filter-pill-${index}`}
              />
            ))}
        </Slider>
      </div>
    </FilterCarouselContainer>
  );
};

export default FilterCarousel;
