import tw from 'tailwind-styled-components';

export const CustomerReviewWrapper = tw.div`
    2xl:py-[144px] 
    2xl:px-[60px] 
    xl:px-10 
    xl:py-[120px] 
    py-10 
    px-5 
    max-w-[1920px] 
    mx-auto
`;

export const OverAllRatingsContainer = tw.div`
    box-border 
    border-4 
    2xl:p-10
    xl:p-[30px]
    p-5 
    w-full
    md:w-[435px] 
    md:h-[365px]
    xl:h-full
    2xl:min-w-[608px]
    2xl:w-[736px] 
    2xl:max-h-[286px] 
    xl:min-w-[435px] 
    2xl:flex 
    2xl:justify-between
`;

export const ReviewsContainer = tw.div`
    w-full
    2xl:max-w-[888px]
`;

export const StarRatingsContainer = tw.div`
    items-center 
    font-bold 
    [&_svg]:!text-resin
`;

export const SortingSelectionContainer = tw.div`
    xl:flex 
    justify-between
    [&_button]:!border-2 
    [&_button]:!border-leaf 
    [&_svg]:!fill-leaf 
    [&_button]:!bg-light 
    [&_button]:!text-leaf 
    hover:[&_button]:!text-leaf
`;

export const UserInfoContainer = tw.div`
    xl:max-w-[151px]
`;

export const MostRatedContainerTitle = `
    !text-grey-500
`;

export const ProgressBarContainer = tw.div`  
    grid
    rows-5
    gap-y-4
    xl:gap-y-5
`;

export const ShowReviewsContainer = tw.div`
    2xl:mt-12 
    xl:min-w-[741px] 
    2xl:max-w-[888px]
`;

export const TopHeadingStyles = `
    2xl:pb-10 
    pb-4 
    max-w-[736px]
`;

export const ProgressBarWrapper = tw.div`
    flex
    items-center
    justify-between
    gap-2
`;

export const IconnButtonStyles = ` 
    [&_svg]:fill-grey-400 
    hover:[&_svg]:fill-black
    !rounded-full
    !border-grey-480
`;

export const OverAllRatingsInnerContainer = tw.div`
    flex 
    2xl:flex-col
    2xl:justify-between
    flex-row
    2xl:items-start
    items-center
    gap-6
    border-b
    border-gray-200
    pb-5
    mb-5
    md:border-b-0
`;

export const ProgressBarInnerContainer = tw.div`
    flex
    items-center
    justify-between
`;

export const CheckboxContainer = tw.div`
    2xl:w-[112px]
    xl:w-[76px]
    w-[96px]
    !overflow-hidden  
`;

export const ReviewCommnetsStyling = `
    xl:max-w-[600px] 
    2xl:max-w-[736px]
`;

export const SelectedFilterPills = tw.div`
    flex
    gap-x-3
    gap-y-6
    py-6
    flex-wrap
`;

export const OpenDivReviewWrapper = tw.div`
    w-full
    py-8 
    2xl:py-10
    grid 
    rows-2
    gap-y-[20px]
`;

export const IndividualUserStarRating = tw.div`
    flex 
    items-center 
    space-x-2 
    xl:block
`;

export const VerifiedUserContainer = tw.div`
    md:pb-[10px]
    pb-2
    flex
    gap-2
`;

export const PaginationMarginDiv = tw.div`
    mt-[30px]
`;

export const FlexClass = tw.div`
    md:flex
    2xl:space-x-[176px]
    md:space-x-6
`;

export const UserInfoInnerContainer = tw.div`
    flex 
    flex-row
    md:flex-col
    gap-3
    md:gap-[6px]
`;

export const ShowMoreButton = `
    overflow-hidden
    max-w-[175px] 
    xl:max-w-[205px] 
    max-h-[64px] 
    whitespace-nowrap
`;

export const MostRatedItemsGrid = tw.div`
    flex 
    flex-col 
    xl:gap-10 
    2xl:gap-12 
    gap-8 
    mt-10 
    xl:mt-0
`;

export const ShowMoreContainer = tw.div`
    flex 
    flex-col
    xl:gap-10 
    2xl:gap-12 
    gap-10
`;

export const FilterPillButtonWrapper = tw.div`
    md:flex 
    hidden
    md:flex-wrap 
    gap-3
`;

export const FilterCarouselContainer = tw.div`
    w-full 
    md:hidden
    [&_div.slick-track]:flex
    [&_div.slick-track]:items-start
    [&_div.slick-track]:justify-start
    [&_div.slick-track]:gap-3.5
    md:[&_div.slick-track]:gap-5
    2xl:[&_div.slick-track]:gap-6
`;

export const PreviousIconContainer = tw.div`
    absolute 
    left-5
    z-10 
    flex
    items-center 
    justify-center
`;

export const PreviousIconWrapper = tw.div`
    flex 
    h-10 
    w-10 
    items-center 
    justify-center 
    bg-white
`;

export const PreviousIconGredient = tw.div`
    h-10 
    w-10
    bg-gradient-to-r 
    from-white
    to-white-color-100
`;

export const NextIconContainer = tw.div`
    absolute 
    right-4
    z-10 
    flex
    items-center 
    justify-center 
`;

export const NextIconGredient = tw.div`
    h-10
    w-10
    bg-gradient-to-l 
    from-white
    to-white-color-100
`;

export const NextIconWrapper = tw.div`
    flex 
    h-10 
    w-10 
    items-center 
    justify-center 
    bg-white
`;
