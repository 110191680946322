import { SegmentEvents } from 'helpers/constants/forms';
import { ICardListClickedEvent, SegementTrackEvent } from './segment-helper';

export const cardListClickedTrack = (value) => {
  try {
    const data: ICardListClickedEvent = {
      ...value,
    };
    return SegementTrackEvent(SegmentEvents.CARD_LIST_CLICKED, data, { sendGeoData: true });
  } catch (err) {
    throw Error(err);
  }
};
