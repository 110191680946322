import { CommonButton } from 'components/atoms/buttons/common-buttons/button';
import { IconWithTextButton } from 'components/atoms/buttons/icon-button/icon-with-text';
import { SelectedFilterPills } from './styles';

interface SelectedPillsProps {
  selectedItems: Array<{ label: string; key: string }>;
  onClearAll: () => void;
  handleItemClick: ({ label }: { label: string }) => void;
}

const SelectedPills = ({ selectedItems = [], onClearAll, handleItemClick }: SelectedPillsProps) => {
  const SelectedFilterPill = selectedItems?.map((item, index) => (
    <IconWithTextButton
      key={index}
      button-type="secondary"
      iconPosition="after"
      icon={'x'}
      color="green"
      size="sm"
      onClick={() => handleItemClick({ label: item.label })}
    >
      {item?.label || ''}
    </IconWithTextButton>
  ));

  return (
    <SelectedFilterPills>
      {SelectedFilterPill}
      {selectedItems.length > 0 && (
        <CommonButton
          button-type="tertiary"
          color="green"
          onClick={() => onClearAll?.()}
          data-testid="Clear-all-button"
          className="!p-0"
        >
          Clear all
        </CommonButton>
      )}
    </SelectedFilterPills>
  );
};

export default SelectedPills;
