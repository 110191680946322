'use client';

import { useEffect, useState } from 'react';
import { usePathname } from 'next/navigation';
import { reviewIndex } from 'helpers/algolia/clients';
import { icons } from 'helpers/constants/review-tags';
import { useAppSelector } from 'redux/hooks';
import { RootState } from 'redux/store';

const useProductReview = () => {
  const path = usePathname();
  const productId =
    useAppSelector((state: RootState) => state?.product?.product_id || null) || path.split('/')[7] || 598868;
  const [state, setState] = useState({
    showAllReview: false,
    activities: [],
    feelings: [],
    rating: [],
    totalPage: 0,
    selected: [],
    averageRating: 0,
  });
  const updateState = (data: any) => setState((state) => ({ ...state, ...data }));
  const handleFilter = (item) => {
    //@ts-ignore
    setState((prev) => {
      //@ts-ignore
      const isAlreadyFavorite = prev?.selected?.some((data) => data?.label === item?.label);
      const updatedSelect = isAlreadyFavorite
        ? //@ts-ignore
          prev?.selected?.filter((data) => data?.label !== item?.label)
        : [...prev?.selected, item];

      return { ...prev, selected: updatedSelect };
    });
  };
  const handleClearAll = () => {
    updateState({
      selected: [],
    });
  };
  const handleShowReviews = () => {
    updateState({
      showAllReview: true,
    });
  };
  const formatConditions = (inputArray, index = 0, conditionsMap = new Map()) => {
    if (index >= inputArray.length) {
      const outputString = Array.from(conditionsMap.values())
        .map((group) => `(${group.join(' OR ')})`)
        .join(' AND ');
      return outputString === '' ? '' : `AND ${outputString}`;
    }
    const item = inputArray[index];
    const key = item?.key;
    const value = item?.value && item?.value?.replace("'", "''");
    if (!conditionsMap.has(key)) {
      conditionsMap.set(key, []);
    }
    conditionsMap?.get(key).push(`${key}:'${value}'`);
    return formatConditions(inputArray, index + 1, conditionsMap);
  };
  const getFilterData = async () => {
    reviewIndex
      .search('', {
        facets: ['*'],
        filters: `product_id = ${productId} AND NOT hidden:true`,
      })
      .then((res) => {
        if (res) {
          const rating =
            Array.from({ length: 5 }, (_, index) => {
              const value = index + 1;
              return {
                label: `${value} star`,
                value: String(value),
                count: res?.facets?.rating[value] || 0,
              };
            })?.sort((a, b) => Number(b?.value) - Number(a?.value)) || [];
          const weightedSum = rating?.reduce((sum, star) => {
            return sum + parseInt(star.value, 10) * star.count;
          }, 0);
          const totalCount = rating?.reduce((sum, star) => {
            return sum + star.count;
          }, 0);
          updateState({
            activities:
              (res?.facets?.activities &&
                Object.entries(res?.facets?.activities)
                  ?.map(([name, count]) => ({
                    label: name || '',
                    value: name || '',
                    count: Number(count),
                    icon: icons[name],
                  }))
                  ?.sort((a, b) => Number(b?.count) - Number(a?.count))
                  ?.slice(0, 3)) ||
              [],
            feelings:
              (res?.facets?.feelings &&
                Object.entries(res?.facets?.feelings)
                  ?.map(([name, count]) => ({
                    label: name || '',
                    value: name || '',
                    count: Number(count),
                    icon: icons[name],
                  }))
                  ?.sort((a, b) => Number(b?.count) - Number(a?.count))
                  ?.slice(0, 3)) ||
              [],
            rating,
            averageRating: Number(totalCount > 0 ? weightedSum / totalCount : 0).toFixed(1) || 0,
            totalPage: res.nbHits,
          });
        }
      })
      .catch((e) => console.log(e));
  };
  useEffect(() => {
    if (productId) {
      getFilterData();
    }
  }, [productId]);
  return {
    ...state,
    fiterPills: {
      activities: state?.activities,
      feelings: state?.feelings,
    },
    filters: productId ? `product_id:${productId} AND NOT hidden:true ${formatConditions(state?.selected)}` : '',
    handleFilter,
    handleClearAll,
    handleShowReviews,
  };
};

export default useProductReview;
