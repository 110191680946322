import React from 'react';
import ReactStars from 'react-rating-stars-component';
import Checkbox from 'components/atoms/inputs/checkbox/checkbox-legal';
import { Typography } from 'components/atoms/typography';
import StarEmptyIcon from 'public/icons/figma/rating-star-empty.svg';
import StarFilledIcon from 'public/icons/figma/rating-star-filled.svg';
import StarHalfFilledIcon from 'public/icons/figma/rating-star-half.svg';
import { ProgressLabelOutside } from './progress-bar';
import {
  CheckboxContainer,
  OverAllRatingsContainer,
  OverAllRatingsInnerContainer,
  ProgressBarContainer,
  ProgressBarInnerContainer,
  StarRatingsContainer,
} from './styles';

interface OverallRatingsProps {
  averageRating: number;
  totalReviews: number;
  onPress: (item: { label: string; count: number; value: string; key: string }) => void;
  data: Array<{ label: string; count: number; value: string }>;
  selected: Array<{ label: string; count: number; value: string; key: string }>;
}

const OverallRatings = ({ averageRating, totalReviews, onPress, data, selected }: OverallRatingsProps) => {
  return (
    <OverAllRatingsContainer data-testid="overall-ratings-container">
      <OverAllRatingsInnerContainer>
        <Typography variant="h2">{averageRating || 0}</Typography>
        <div>
          <StarRatingsContainer data-testid="star-ratings-container">
            <ReactStars
              key={`stars_${averageRating}`}
              count={5}
              value={Math.max(0, averageRating)}
              size={24}
              color={'#FFF'}
              activeColor={'#CF4520'}
              edit={false}
              halfIcon={<StarHalfFilledIcon />}
              filledIcon={<StarFilledIcon />}
              emptyIcon={<StarEmptyIcon color="#CF4520" />}
              isHalf={true}
            />
          </StarRatingsContainer>
          <Typography variant="small" className={'pt-2 !text-grey-400 xl:pt-2.5 2xl:pt-3'}>
            {totalReviews} reviews
          </Typography>
        </div>
      </OverAllRatingsInnerContainer>
      <div>
        <ProgressBarContainer>
          {data?.map(({ label, count, value }, index) => (
            <ProgressBarInnerContainer key={index} className={index === 4 ? '' : '!mb-0'}>
              <CheckboxContainer key={index}>
                <Checkbox
                  checked={selected?.some((item) => item?.label === label)}
                  onChange={() => {
                    onPress({ label, count, value, key: 'rating' });
                  }}
                  label={label}
                  disabled={Boolean(count === 0)}
                  data-testid={`checkbox-container-${index}`}
                />
              </CheckboxContainer>
              <ProgressLabelOutside ratingCount={count} totalReviews={totalReviews} />
            </ProgressBarInnerContainer>
          ))}
        </ProgressBarContainer>
      </div>
    </OverAllRatingsContainer>
  );
};

export default OverallRatings;
