import { formatDate } from 'date-fns';
import ReactStars from 'react-rating-stars-component';
import { Typography } from 'components/atoms/typography';
import StarEmptyIcon from 'public/icons/figma/rating-star-empty.svg';
import StarFilledIcon from 'public/icons/figma/rating-star-filled.svg';
import StarHalfFilledIcon from 'public/icons/figma/rating-star-half.svg';
import Verified from 'public/icons/figma/verified.svg';
import ReviewIcon from './review-icon';
import {
  IndividualUserStarRating,
  OpenDivReviewWrapper,
  ReviewCommnetsStyling,
  UserInfoContainer,
  UserInfoInnerContainer,
  VerifiedUserContainer,
} from './styles';

interface FilteredReviewsProps {
  reviewData: any;
}
const FilteredReviews = ({ reviewData }: FilteredReviewsProps) => {
  return (
    <div className="relative">
      {reviewData &&
        reviewData?.map((review, index) => {
          const reviewIcon = review?.activities?.concat(review?.feelings);
          return (
            <div key={index} data-testid={`review-container-${index}`}>
              <OpenDivReviewWrapper>
                <UserInfoContainer data-testid={`user-info-${index}`}>
                  <UserInfoInnerContainer>
                    {review.nickname && (
                      <Typography
                        variant="body-small-bold"
                        className={'h-5 pb-1 !text-grey-400 xl:w-[46px]'}
                        data-testid={`user-initials-${index}`}
                      >
                        {review.nickname}
                      </Typography>
                    )}
                    <VerifiedUserContainer data-testid={`verified-user-${index}`}>
                      <Verified />
                      <Typography variant="body-small" className="!text-grey-400">
                        Verified purchase
                      </Typography>
                    </VerifiedUserContainer>
                  </UserInfoInnerContainer>
                  <IndividualUserStarRating data-testid={`star-rating-${index}`}>
                    <ReactStars
                      key={`stars_${review?.rating}`}
                      count={5}
                      value={Math.max(0, review?.rating)}
                      size={24}
                      color={'#FFF'}
                      activeColor={'#CF4520'}
                      edit={false}
                      halfIcon={<StarHalfFilledIcon />}
                      filledIcon={<StarFilledIcon />}
                      emptyIcon={<StarEmptyIcon color="#CF4520" />}
                      isHalf={true}
                    />
                    <Typography variant="body-small" className={'!text-grey-400 xl:absolute xl:right-0 xl:mt-[-55px]'}>
                      {formatDate(review.created_at, 'MMMM dd,yyyy')}
                    </Typography>
                  </IndividualUserStarRating>
                </UserInfoContainer>
                <div className="row-2 grid gap-y-4 xl:gap-y-3 2xl:gap-y-4">
                  <Typography
                    variant="body-large"
                    className={ReviewCommnetsStyling}
                    data-testid={`review-comment-${index}`}
                  >
                    {review.comment}
                  </Typography>
                  <div className="flex flex-wrap gap-2">
                    {reviewIcon &&
                      reviewIcon?.map((item, index) => (
                        <div data-testid={`review-icon-${index}`} key={index}>
                          <ReviewIcon item={item} />
                        </div>
                      ))}
                  </div>
                </div>
              </OpenDivReviewWrapper>
              <hr />
            </div>
          );
        })}
    </div>
  );
};

export default FilteredReviews;
